import React, { ReactNode, useEffect, useRef, useState } from 'react'
import tokens from '@te-digi/styleguide-tokens'

import { useBreakpoint } from '@te-digi/styleguide'

type AutoScaleProps = {
  children: ReactNode
}

export const AutoScale = ({ children }: AutoScaleProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(0)
  const [scale, setScale] = useState(1)
  const isMd = useBreakpoint('md')
  const isLg = useBreakpoint('lg')

  const tmpBreakpoint = isMd ? parseInt(tokens.breakpoint.md) : 320
  const width = isLg ? 1400 : tmpBreakpoint

  useEffect(() => {
    const updateState = () => {
      if (!wrapperRef.current || !contentRef.current) {
        return
      }

      const newScale = wrapperRef.current.offsetWidth / width

      setScale(newScale < 1 ? newScale : 1)
      setContentHeight(Math.floor(newScale * contentRef.current.offsetHeight))
    }

    updateState()

    window.addEventListener('resize', updateState)

    return () => {
      window.removeEventListener('resize', updateState)
    }
  }, [width])

  return (
    <div
      ref={wrapperRef}
      style={{
        height: contentHeight,
        pointerEvents: 'none',
        position: 'relative'
      }}
    >
      <div
        ref={contentRef}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: '0 0 0',
          width
        }}
      >
        {children}
      </div>
    </div>
  )
}
