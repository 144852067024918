import React from 'react'
import styled from 'styled-components'

import {
  Alert,
  ArrowLeftIcon,
  Button,
  Buttons,
  Collapsible,
  Collapsibles,
  Columns,
  DescriptionList,
  DescriptionListItem,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Help,
  Inline,
  Input,
  List,
  ListItem,
  Main,
  Message,
  MessageHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  PenIcon,
  PostponeIcon,
  Radio,
  RadioGroup,
  SideStep,
  SideSteps,
  Spacer,
  StatusBarItem,
  Text,
  Textarea,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { HeaderExample } from '../../examples/react/HeaderExamples'
import { LinkMock } from '../../components/LinkMock'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { StyledStatusBar } from '../palautteet/statusbar'
import { Link } from '../../components/Link'
import { FooterExample } from '../../examples/react/FooterExample'
import { Playground } from '../../components/Playground'

const Edit = () => (
  <Button
    iconLeft={<PenIcon />}
    variant="plain"
  >
    Siirry korjaamaan
  </Button>
)

const ModalWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  padding: 2rem;
`

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Lomakkeen virheenkäsittely">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Lomakkeelle syötetty tieto voi olla virheellinen kahdesta syystä:
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Käyttäjän syöttämä tieto ei vastaa validoinnissa määriteltyjä
                vaatimuksia
              </strong>{' '}
              (esim. tieto on väärässä muodossa).
            </ListItem>
            <ListItem>
              <strong>Käyttäjä ei ole täyttänyt pakollista tietoa</strong>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <List variant="unordered">
        <ListItem>
          <strong>Yksittäinen lomakekenttä validoidaan</strong> kun käyttäjä
          poistuu siitä (<Code>onBlur</Code>)
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Lomakekenttä validoidaan vain jos käyttäjä on koskenut siihen (
              <Code>touched</Code>).
            </ListItem>
            <ListItem>
              Lomakekentän virhe esitetään komponenttien virhetilasääntöjen
              mukaan, yleensä komponentin alapuolella näytettävällä ja
              ruudunlukijalle luettavalla virheviestillä (määritellään
              komponentin <Code>error</Code>-propilla).
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>Koko lomake validoidaan</strong>, kun käyttäjä painaa
          lomakkeen tiedot lähettävää painiketta (yleensä Lähetä-, Tallenna- tai
          Julkaise-toiminto)
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Virheistä tai puuttuvista pakollisista tiedoista lisätään
              virheilmoitus (
              <Code>
                <Link page="Alert" />
              </Code>
              ) painikkeen yläpuolelle (kts.{' '}
              <a href="#rakenne-ja-toiminnallisuus">
                Rakenne ja toiminnallisuus -osio
              </a>
              ).
            </ListItem>
            <ListItem>
              Virheilmoitus poistuu heti, kun käyttäjä on korjannut kaikki
              virheet ja puutteet.
            </ListItem>
            <ListItem>
              Pitkillä tai monisivuisella lomakkeella virheilmoituksen
              poistumisen yhteydessä käyttäjälle ilmoitetaan väliaikaisella
              ilmoituksella (
              <Code>
                <Link page="Notifications" />, status='success'
              </Code>
              ), että lomakkeella ei ole enää virheitä.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>Monisivuisen lomakkeen sivu</strong> validoidaan lisäksi
          käyttäjän navigoidessa lomakesivujen välillä.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Sivua ei kuitenkaan validoida, jos käyttäjä ei ole koskenut
              sivulla yhteenkään kenttää (kts tarkemmin kohdasta{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>)
            </ListItem>
          </List>
        </ListItem>
      </List>
      <List variant="unordered">
        <ListItem>
          <strong>
            Lomakkeen tiedot lähettävät painike on aina aktiivinen,
          </strong>{' '}
          vaikka lomakkeella olisi virheitä tai täyttämättömiä tietoja.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Näin käyttäjä voi milloin tahansa tarkistuttaa lomakkeen
              painamalla tiedot lähettävää painiketta, joka suorittaa
              validoinnin.
            </ListItem>
            <ListItem>
              Validoivan painikkeen painaminen voi tuoda esiin myös muita
              tilanteesta ja lomakkeen täytetyistä tiedoista riippuvia
              virheilmoituksia, joita ei ole ennen validointia voitu esittää.
              (esim. lomakkeelle syötetyt päivämäärät validoidaan
              lähetyspäivämäärän perusteella vasta käyttäjän painaessa
              Lähetä-painiketta)
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>
            Jos lomakkeella on Jatka myöhemmin tai Tallenna luonnoksena
            -toiminto,
          </strong>{' '}
          voivat tallennettavan luonnoksen validointisäännöt olla kevyemmät kuin
          varsinaisen lähetystoiminnon (esim. voidaan vaatia täyttämään vain
          nimi)
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Esimerkiksi luonnosta tallennettaessa kaikkia pakollisia kenttiä
              ei tarkisteta, mutta kentät jotka joihin käyttäjä on syöttänyt
              sisältöä validoidaan normaalisti
            </ListItem>
            <ListItem>
              Validoinnin virheilmoituksessa tarkennetaan mitkä tiedot käyttäjän
              on pakollista täyttää voidakseen tallentaa luonnoksen.
            </ListItem>
            <ListItem>
              Monisivuisella lomakkeella tietojen lähetyssivulla näytetään
              edelleen varoitukset kaikista puuttuvista pakollisista tiedoista.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>
            Koko lomake validoidaan myös silloin, kun käyttäjä palaa muokkaamaan
            aiemmin täytettyjä tietoja.
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Näin varmistetaan, että lomakkeelle ei ole tullut käyttäjästä
              riippumattomia virheitä (esim. validointisääntöjen tiukentumisen
              myötä virheellisiksi muuttuneita kenttiä tai kielletyjä merkkejä
              käyttöliittymän ohi tulevassa datassa)
            </ListItem>
            <ListItem>
              Validoinnissa tarkistetaan vain syötetyt tiedot, ei puuttuvia
              pakollisia tietoja. Näin esim. kesken jätetylle lomakkeelle tai
              luonnokselle palaaminen ei näytä tyhjiä pakollisia kenttiä
              virheellisinä.
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Paragraph>
        <strong>
          Virheistä tai puuttuvista pakollisista tiedoista lisätään
          virheilmoitus
        </strong>{' '}
        (
        <Code>
          <Link page="Alert" />
        </Code>
        ) lomakkeen lähettävän tai modaalin hyväksyvän toimintopainikkeen
        yläpuolelle.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Virheilmotuksissa voidaan käyttää joko alla olevia geneerisiä
          ilmoituksia, tai kontekstiin paremmin sopivaa tekstiä.
        </ListItem>
        <ListItem>
          Mallista voi tarvittaessa muuttaa "ilmoitus" -sanan tarkoitukseen
          sopivammaksi.
        </ListItem>
      </List>
      <NoteItem
        bullet="1"
        title={<>Virheilmoitus lomakkeella</>}
      >
        <Playground>
          <Alert color="danger">
            Ilmoituksessasi on virheellisiä tai puutteellisia tietoja. Tarkasta
            ja korjaa tiedot. Et voi lähettää ilmoitustasi ennen kuin olet
            täyttänyt kaikki pakolliset tiedot.
          </Alert>
        </Playground>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={<>Virheilmoitus modaalissa</>}
      >
        <Playground>
          <Alert color="danger">
            Tiedoissa on virheitä tai puutteita. Tarkasta ja korjaa tiedot.
          </Alert>
        </Playground>
      </NoteItem>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Monisivuinen lomake</SectionHeading>
      <List variant="unordered">
        <ListItem>
          <strong>
            Jos lomakkesivulla on validoitu, virhetilassa oleva lomakekenttä
          </strong>
          , sivulle asetetaan{' '}
          <Code>
            <Link page="SideSteps" />
          </Code>
          -navigaatiossa <Code>status='error'</Code>.
        </ListItem>
        <ListItem>
          <strong>
            Monisivuisen lomakkeen sivu validoidaan kun käyttäjä vaihtaa sivua.
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>Sivua ei validoida</strong>, jos käyttäjä ei ole koskenut
              yhteenkään kenttään (mikään kentistä ei ole <Code>touched</Code>).
            </ListItem>
            <ListItem>
              <strong>Jos sivulta löytyy validointivirheitä</strong>, sivulle
              asetetaan{' '}
              <Code>
                <Link page="SideSteps" />
              </Code>
              -navigaatiossa <Code>status='error'</Code>.
            </ListItem>
            <ListItem>
              Jos lomakkeella on Jatka myöhemmin -toiminto tai Tallenna
              luonnoksena -toiminto, validointi sivua vaihtaessa voi noudattaa
              luonnokselta vaadittavia kevyempiä validointisääntöjä.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>
            Koko lomakkeen validointivirheistä varoitetaan vasta kun käyttäjä
            painaa lomakkeen tiedot lähettävää toimintopainiketta
          </strong>{' '}
          (yleensä Lähetä-, Tallenna- tai Julkaise-toiminto)
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Jos validoinnissa lomakkeelta löytyy virheitä tai pakollisten
                tietojen puutteita, jotka estävät lomakkeen lähettämisen,
              </strong>{' '}
              Tietojen lähetys -sivulla näytetään:
              <List
                noMargin
                variant="unordered"
              >
                <ListItem>
                  <strong>Virheilmoitus</strong> (
                  <Code>
                    <Link page="Alert" />
                  </Code>
                  ) lähettävän painikkeen yläpuolella,
                </ListItem>
                <ListItem>
                  <strong>Siirry korjaamaan -painike</strong> kunkin
                  virheellisen tai puuttuvan pakollisen tiedon kohdalla. Painike
                  ohjaa käyttäjän suoraan kyseisen osioon, jossa hän voi
                  täydentää tai korjata tiedon. Painike linkitetään kyseisen
                  tiedon labeliin <Code>aria-labelledby</Code>-attribuutilla.
                </ListItem>
                <ListItem>
                  Lisäksi lomakkeen SideSteps-navigaatiossa{' '}
                  <strong>
                    Tietojen lähetys -sivu asetetaan virhetilaiseksi
                  </strong>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>
            Käyttäjän navigoidessa Tietojen lähetys -sivulle, ei koko lomakeen
            validointivirheistä vielä varoiteta, eikä Sidesteps-navigaatiossa
            sivuja muuteta virhetilaan.
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Jos virheitä on jo ilmennyt jo aiemmissa kenttä-tai
              sivukohtaisissa validoinneissa, virheelliset sivut näytetään
              edelleen <Code>status="error"</Code>
              -tilaisena sivunavigaatiossa.
            </ListItem>
            <ListItem>
              Täysin tyhjien sivujen kohdalla (sivulla mikään kentistä ei ole{' '}
              <Code>touched</Code>) ei näytetä sivunavigaatiossa erroria, vaikka
              pakollisia tietoja puutuisikin.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>
            Tietojen tarkistus -sivulla puuttuvien pakollisten tietojen kohdalla
          </strong>{' '}
          näytetään aina virheilmoitus "Pakollinen tieto" jo ennen varsinaisen
          validoinnin suorittamista, vaikka käyttäjä ei ole vielä alkanut
          täyttää kyseisiä kohtia.
        </ListItem>
      </List>
      <Help>
        <Paragraph noMargin>
          Tietyillä Työmarkkinatorin monisivuisilla lomakkeilla tallennetaan
          validoinnissa tapahtuvan bugin välttämiseksi näkymätön luonnos aina
          käyttäjän vaihtaessa sivua. Tallennettu luonnos poistetaan jos
          käyttäjä painaa Keskeytä-painiketta.
        </Paragraph>
      </Help>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Yksisivuisen lomakkeen virheilmoitus</SectionHeading>
      <Paragraph>
        Virheilmoitus näytetään, kun painetaan <strong>Lähetä</strong>
        -painiketta. Samalla virheelliset ja puutteelliset kentät korostetaan.
      </Paragraph>
      <ZoomedExample>
        <>
          <HeaderExample />
          <Columns layout="single">
            <Main>
              <Heading>Lomakkeen otsikko</Heading>
              <FormLayout>
                <FormLayoutItem
                  header={
                    <Heading
                      size={3}
                      level={2}
                    >
                      Ensimmäisen osion otsikko
                    </Heading>
                  }
                >
                  <FormGroup>
                    <Input
                      error="Pakollinen tieto"
                      label="Lorem ipsum dolor sit amet"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Textarea
                      error="Pakollinen tieto"
                      label="Nulla accumsan tortor"
                      required
                    />
                  </FormGroup>
                  <RadioGroup
                    error="Pakollinen tieto"
                    label="Phasellus vehicula diam"
                    onChange={() => {}}
                    required
                  >
                    <Radio value="1">Etiam a scelerisque nisl</Radio>
                    <Radio value="2">Vel aliquam libero</Radio>
                  </RadioGroup>
                </FormLayoutItem>
              </FormLayout>
              <Spacer marginTop="xl">
                <Alert color="danger">
                  Ilmoituksessasi on virheellisiä tai puutteellisia tietoja.
                  Tarkasta ja korjaa tiedot. Et voi lähettää ilmoitustasi ennen
                  kuin olet täyttänyt kaikki pakolliset tiedot.
                </Alert>
                <Buttons align="center">
                  <Button
                    iconLeft={<XIcon />}
                    variant="plain"
                  >
                    Keskeytä
                  </Button>
                  <Button
                    iconLeft={<PostponeIcon />}
                    variant="plain"
                  >
                    Jatka myöhemmin
                  </Button>
                  <Button color="secondary">Lähetä</Button>
                </Buttons>
              </Spacer>
            </Main>
          </Columns>
          <StyledStatusBar saveStatus="saved">
            <StatusBarItem>
              Et ole vielä lähettänyt ilmoittautumista
            </StatusBarItem>
          </StyledStatusBar>
          <FooterExample />
        </>
      </ZoomedExample>
      <Spacer marginTop="layout-md">
        <SectionHeading>Lomakkeen virheilmoitus modaalissa</SectionHeading>
      </Spacer>
      <ZoomedExample>
        <ModalWrapper>
          <ModalContent size="lg">
            <ModalHeader>
              <ModalHeading>Otsikko</ModalHeading>
            </ModalHeader>
            <ModalBody>
              <FormLayout>
                <FormLayoutItem color="none">
                  <FormGroup>
                    <Input
                      error="Pakollinen tieto"
                      label="Lorem ipsum dolor sit amet"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Textarea
                      error="Pakollinen tieto"
                      label="Nulla accumsan tortor"
                      required
                    />
                  </FormGroup>
                  <RadioGroup
                    error="Pakollinen tieto"
                    label="Phasellus vehicula diam"
                    onChange={() => {}}
                    required
                  >
                    <Radio value="1">Etiam a scelerisque nisl</Radio>
                    <Radio value="2">Vel aliquam libero</Radio>
                  </RadioGroup>
                </FormLayoutItem>
              </FormLayout>
              <Spacer marginTop="xl">
                <Alert
                  color="danger"
                  noMargin
                >
                  Tiedoissa on virheitä tai puutteita. Tarkasta ja korjaa
                  tiedot.
                </Alert>
              </Spacer>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline">Peruuta</Button>
              <Button>Tallenna</Button>
            </ModalFooter>
          </ModalContent>
        </ModalWrapper>
      </ZoomedExample>
      <Spacer marginTop="layout-md">
        <SectionHeading>Monisivuisen lomakkeen virheilmoitus</SectionHeading>
      </Spacer>
      <ZoomedExample>
        <>
          <HeaderExample
            activePrimaryItem="Omat sivut"
            hasUser
            hasCompany
          />
          <Columns
            layout="sidebar"
            sidebar={
              <SideSteps label="Lomakkeen pääotsikko">
                <SideStep
                  as={LinkMock}
                  status="error"
                >
                  Ensimmäinen sivu
                </SideStep>
                <SideStep
                  as={LinkMock}
                  status="error"
                >
                  Toinen sivu
                </SideStep>
                <SideStep
                  active
                  as={LinkMock}
                  status="error"
                >
                  Tietojen lähetys
                </SideStep>
              </SideSteps>
            }
          >
            <Main>
              <Heading>Tietojen lähetys</Heading>
              <Message attentionColor="secondary">
                <MessageHeader>
                  <Heading
                    level={2}
                    noMargin
                    size={4}
                  >
                    Tärkeä huomautettava asia
                  </Heading>
                </MessageHeader>
              </Message>
              <Spacer marginBottom="xxl">
                <Paragraph>
                  Ilmoitus käsitellään antamiesi vastausten perusteella.
                  Valitsemalla "Lähetä" vakuutat antamasi tiedot oikeiksi.
                </Paragraph>
              </Spacer>
              <Heading
                size={3}
                level={3}
              >
                Ensimmäinen sivu
              </Heading>
              <FormLayout>
                <FormLayoutItem
                  color="white"
                  header={
                    <Inline
                      gap="md"
                      justifyContent="between"
                    >
                      <Heading
                        size={4}
                        level={4}
                      >
                        Ensimmäisen osion otsikko
                      </Heading>
                      <Button
                        iconLeft={<PenIcon />}
                        variant="plain"
                      >
                        Muokkaa
                      </Button>
                    </Inline>
                  }
                >
                  <DescriptionList noMargin>
                    <DescriptionListItem
                      label="Lorem ipsum dolor"
                      labelWidth={4}
                    >
                      Sit amet consectetuer adipiscing elit
                    </DescriptionListItem>
                    <DescriptionListItem
                      label="Sed posuere"
                      labelWidth={4}
                    >
                      <Text
                        as="p"
                        color="danger"
                      >
                        Pakollinen tieto
                      </Text>
                      <Edit />
                    </DescriptionListItem>
                    <DescriptionListItem
                      label="Ligula eros"
                      labelWidth={4}
                    >
                      Ullamcorper quis lacinia
                    </DescriptionListItem>
                  </DescriptionList>
                </FormLayoutItem>
                <FormLayoutItem
                  header={
                    <Inline
                      gap="md"
                      justifyContent="between"
                    >
                      <Heading
                        size={4}
                        level={4}
                      >
                        Toisen osion otsikko
                      </Heading>
                      <Button
                        iconLeft={<PenIcon />}
                        variant="plain"
                      >
                        Muokkaa
                      </Button>
                    </Inline>
                  }
                  color="white"
                >
                  <DescriptionList noMargin>
                    <DescriptionListItem
                      label="Mauris sed libero"
                      labelWidth={4}
                    >
                      Suspendisse facilisis
                    </DescriptionListItem>
                    <DescriptionListItem
                      label="Nulla in lacinia"
                      labelWidth={4}
                    >
                      Laoreet lorem velit accumsan
                    </DescriptionListItem>
                    <DescriptionListItem
                      label="Velit"
                      labelWidth={4}
                    >
                      <Text
                        as="p"
                        color="danger"
                      >
                        Virheilmoitus
                      </Text>
                      <Edit />
                    </DescriptionListItem>
                  </DescriptionList>
                </FormLayoutItem>
              </FormLayout>
              <Divider />
              <Heading
                size={3}
                level={3}
              >
                Toinen sivu
              </Heading>
              <FormLayout>
                <FormLayoutItem
                  color="none"
                  header={
                    <>
                      <Inline
                        gap="md"
                        justifyContent="between"
                      >
                        <Heading
                          size={4}
                          level={4}
                        >
                          Ensimmäisen osion otsikko
                        </Heading>
                        <Button
                          iconLeft={<PenIcon />}
                          variant="plain"
                        >
                          Muokkaa
                        </Button>
                      </Inline>
                      <Help variant="error">
                        <Text as="p">Virheilmoitus</Text>
                        <Edit />
                      </Help>
                    </>
                  }
                >
                  <Collapsibles>
                    <Collapsible
                      bordered
                      label={
                        <Heading
                          level={5}
                          noMargin
                          size={4}
                        >
                          Lorem ipsum dolor sit amet
                        </Heading>
                      }
                      subLabel="Consectetuer adipiscing elit"
                    />
                    <Collapsible
                      bordered
                      label={
                        <Heading
                          level={5}
                          noMargin
                          size={4}
                        >
                          Sed posuere interdum sem
                        </Heading>
                      }
                      subLabel="Quisque ligula eros ullamcorper quis"
                    />
                  </Collapsibles>
                </FormLayoutItem>
              </FormLayout>
              <Divider />
              <Button
                iconLeft={<ArrowLeftIcon />}
                variant="outline"
              >
                Edellinen
              </Button>
              <Divider />
              <Alert color="danger">
                Ilmoituksessasi on virheellisiä tai puutteellisia tietoja.
                Tarkasta ja korjaa tiedot. Et voi lähettää ilmoitustasi ennen
                kuin olet täyttänyt kaikki pakolliset tiedot.
              </Alert>
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  iconLeft={<PostponeIcon />}
                  variant="plain"
                >
                  Jatka myöhemmin
                </Button>
                <Button color="secondary">Lähetä</Button>
              </Buttons>
            </Main>
          </Columns>
          <StyledStatusBar saveStatus="saved">
            <StatusBarItem>
              Et ole vielä lähettänyt ilmoittautumista
            </StatusBarItem>
          </StyledStatusBar>
          <FooterExample />
        </>
      </ZoomedExample>
    </Section>
  </Content>
)

export default Page
