import React from 'react'
import styled from 'styled-components'

import {
  Col,
  DescriptionList,
  DescriptionListItem,
  Help,
  List,
  ListItem,
  Paragraph,
  Row,
  StatusBar,
  StatusBarItem,
  Text
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Image } from '../../components/Image'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { Link } from '../../components/Link'

export const StyledStatusBar = styled(StatusBar)`
  position: relative;
  transform: none;
`

StyledStatusBar.displayName = 'StatusBar'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="StatusBar"
    components={[{ component: StatusBar }, { component: StatusBarItem }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Sivuilla ja lomakkeilla, joilla on automaattitallennus, on{' '}
          <Code>StatusBar</Code>.
        </ListItem>
        <ListItem>
          <Code>StatusBar</Code>:ssa on tieto automaattitallennuksen tilasta.
          Lisäksi siinä voi olla myös tieto sivun/lomakkeen sen hetkisestä
          tilasta.
        </ListItem>
        <ListItem>
          Mobiilissa <Code>StatusBar</Code>:ssa esitetään vain tallennustieto.
        </ListItem>
        <ListItem>
          Jos kyseessä on lomake, <Code>StatusBar</Code>:ssa ilmoitetaan, että
          lomaketta ei ole vielä lähetetty. Lomake-sanan sijaan sanotaan
          yksityiskohtaisemmin mitä ei ole lähetetty, esim. ilmoitusta,
          selvitystä, suunnitelmaa jne.
        </ListItem>
        <ListItem>
          Jos sivulla ei ole mitään lähetettävää, mietitään tapauskohtaisesti
          miten tila ilmaistaan, esim. "Et ole julkaissut profiiliasi
          työnantajille."
        </ListItem>
      </List>
      <SectionHeading>Tallennustiedon tilat</SectionHeading>
      <DescriptionList variant="striped">
        <DescriptionListItem
          label={<Code>undefined</Code>}
          labelWidth={3}
        >
          Uudella tyhjällä sivulla/lomakkeella ei ole mitään tallennustekstiä,
          jos muutoksia sivulla/lomakkeelle ei ole vielä tehty.
        </DescriptionListItem>
        <DescriptionListItem
          label={<Code>'saved'</Code>}
          labelWidth={3}
        >
          <Text fontWeight="semi-bold">Kaikki tiedot on tallennettu</Text>
          <br />
          Esitetään, kun kaikki sivun/lomakkeen tiedot ovat tallennettu. Tekstin
          perässä esitetään vihreä check-ikoni.
        </DescriptionListItem>
        <DescriptionListItem
          label={<Code>'saving'</Code>}
          labelWidth={3}
        >
          <Text fontWeight="semi-bold">Tallennetaan…</Text>
          <br />
          Esitetään hetkellä, kun tallennetaan tietoja.
        </DescriptionListItem>
        <DescriptionListItem
          label={<Code>Date</Code>}
          labelWidth={3}
        >
          <Text fontWeight="semi-bold">Tallennettu pp.kk.vvvv h.mm</Text>
          <br />
          Esitetään viimeksi tallennettu ajankohta, kun avataan keskeneräinen
          sivu/lomake, johon ei ole vielä tehty tällä kertaa muutoksia.
        </DescriptionListItem>
      </DescriptionList>
    </Section>
    <Section title="Esimerkkejä">
      <SectionHeading>Työnhaun aloitusilmoitus</SectionHeading>
      <Playground>
        <StyledStatusBar saveStatus="saved">
          <StatusBarItem>
            Et ole vielä lähettänyt ilmoittautumista
          </StatusBarItem>
        </StyledStatusBar>
      </Playground>
      <SectionHeading>Työnhaun muutossilmoitus</SectionHeading>
      <Playground>
        <StyledStatusBar saveStatus="saving">
          <StatusBarItem>Et ole vielä lähettänyt ilmoitusta</StatusBarItem>
        </StyledStatusBar>
      </Playground>
      <SectionHeading>Selvityspyyntö</SectionHeading>
      <Playground>
        <StyledStatusBar saveStatus={new Date()}>
          <StatusBarItem>Et ole vielä lähettänyt selvitystä</StatusBarItem>
        </StyledStatusBar>
      </Playground>
      <SectionHeading>Työllistymissuunnitelma</SectionHeading>
      <Playground>
        <StyledStatusBar>
          <StatusBarItem>
            Et ole vielä lähettänyt työllistymissuunnitelmaa
          </StatusBarItem>
        </StyledStatusBar>
      </Playground>
      <SectionHeading>Työnhakuprofiili</SectionHeading>
      <Playground>
        <StyledStatusBar saveStatus="saved">
          <StatusBarItem>
            Et ole julkaissut profiiliasi työnantajille
          </StatusBarItem>
        </StyledStatusBar>
      </Playground>
    </Section>
    <Section title="Sijoittuminen sivulla">
      <Help>
        <Paragraph noMargin>
          <Code>StatusBar</Code> tulee asetella{' '}
          <Code>
            <Link page="Footer" />
          </Code>{' '}
          ja <Code>Columns</Code> -komponenttien väliin.
        </Paragraph>
      </Help>
      <Paragraph>
        Kun <Code>Footer</Code> ei ole näkyvissä, <Code>StatusBar</Code> asettuu
        selainikkunan alalaitaan varjolla.
      </Paragraph>
      <Row gap="md">
        <Col>
          <Image
            alt=""
            src="/statusbar-floating.png"
          />
        </Col>
        <Col xs={2}>
          <Image
            alt=""
            src="/statusbar-mobile-floating.png"
          />
        </Col>
      </Row>
      <Paragraph>
        Kun <Code>Footer</Code> on näkyvissä, <Code>StatusBar</Code> asettuu sen
        yläpuolelle ilman varjoa.
      </Paragraph>
      <Row gap="md">
        <Col>
          <Image
            alt=""
            src="/statusbar-not-floating.png"
          />
        </Col>
        <Col xs={2}>
          <Image
            alt=""
            src="/statusbar-mobile-not-floating.png"
          />
        </Col>
      </Row>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Tuottaa semanttisesti listan. Lista ryhmittelee ruudunlukijan saamaa
        tietoa, ei vain lue asioita peräkkäin.
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Ota huomioon, että komponentti on sijoiteltu sivulla ohjeiden
          mukaisesti. Älä välitä sillä mitään sellaista tietoa, mikä tulee
          välittää notifikaation avulla.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
