import React, { ComponentProps, ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'

import { AutoScale } from './AutoScale'
import { Notes } from './Notes'

interface PlaygroundProps {
  children: ReactNode
  notes?: ComponentProps<typeof Notes>['notes']
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`

const StyledBox = styled(Box)`
  border-color: ${tokens.color['neutral-2']};
  max-width: 100%;
  width: auto;
`

const ZoomedExample = ({ children, notes }: PlaygroundProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [visibleNotes, setVisibleNotes] = useState(true)

  return (
    <StyledDiv aria-hidden>
      <StyledBox
        //@ts-expect-error
        onClick={() => setVisibleNotes(visible => !visible)}
        overflow="hidden"
        padding="none"
        ref={ref}
      >
        <AutoScale>{children}</AutoScale>
        {notes && visibleNotes && (
          <Notes
            parentRef={ref}
            notes={notes}
          />
        )}
      </StyledBox>
    </StyledDiv>
  )
}

export { ZoomedExample }
