import React, { ComponentProps } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as StyleGuideLink } from '@te-digi/styleguide'

import { FLAT_PAGES } from '../pages'

interface LinkProps extends ComponentProps<typeof StyleGuideLink> {
  page?: string
}

const Link = ({ children, page, to, ...rest }: LinkProps) => {
  const pageLink = page
    ? FLAT_PAGES.find(currentPage => currentPage.title === page)
    : undefined

  return (
    <StyleGuideLink
      as={GatsbyLink}
      to={pageLink?.to ?? to}
      {...rest}
    >
      {pageLink?.title ?? children}
    </StyleGuideLink>
  )
}

export { Link }
