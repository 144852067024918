import React, { ComponentProps } from 'react'
import { Image as StyleGuideImage } from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'
import styled from 'styled-components'

const StyledImage = styled(StyleGuideImage)`
  border: 1px solid ${tokens.color['neutral-3']};
  border-radius: ${tokens.radius.md};
`

const Image = (props: ComponentProps<typeof StyleGuideImage>) => (
  <StyledImage
    {...props}
    fluid
  />
)

export { Image }
