import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

type NoteType = {
  id: string
  note?: string
  rects?: {
    height: number
    left: number
    top: number
    width: number
  }[]
}

type NotesProps = {
  notes?: NoteType[]
  parentRef: React.MutableRefObject<HTMLElement | null>
}

const StyledNote = styled.div`
  background-color: rgb(255, 160, 103, 0.15);
  border-radius: ${tokens.radius.md};
  border-top-right-radius: 0;
  position: relative;
`

const StyledId = styled.div`
  background-color: ${tokens.color.warning};
  border-bottom-right-radius: ${tokens.radius.md};
  border-top-right-radius: ${tokens.radius.md};
  font-size: ${tokens['font-size'].sm};
  left: 100%;
  line-height: 1;
  padding: ${tokens.space.xxs};
  position: absolute;
  top: 0;
`

const Notes = ({ notes, parentRef }: NotesProps) => {
  const [notesWithPosition, setNotesWithPosition] = useState<NoteType[]>([])

  useEffect(() => {
    const getPositions = () => {
      const positions = notes?.map(note => {
        if (!parentRef.current) {
          return note
        }

        const elements = Array.from(
          parentRef.current.querySelectorAll(`[data-note='${note.id}']`)
        )

        return {
          ...note,
          rects: elements.map(element => {
            const rect = element.getBoundingClientRect()
            return {
              height: rect.height + 8,
              left: rect.left - 4,
              top: rect.top - 4,
              width: rect.width + 8
            }
          })
        }
      })

      setNotesWithPosition(positions || [])
    }

    window.addEventListener('resize', getPositions)
    window.addEventListener('scroll', getPositions)
    getPositions()

    return () => {
      window.removeEventListener('resize', getPositions)
      window.removeEventListener('scroll', getPositions)
    }
  }, [notes, parentRef])

  if (notesWithPosition.length === 0) {
    return null
  }

  return (
    <>
      {notesWithPosition.map(note =>
        note.rects?.map((rect, i) => (
          <StyledNote
            key={`${note.id}-${i}`}
            style={{
              height: rect.height,
              position: 'fixed',
              left: rect.left,
              top: rect.top,
              width: rect.width
            }}
          >
            <StyledId>{note.id}</StyledId>
          </StyledNote>
        ))
      )}
    </>
  )
}

export { Notes }
