import React, { ReactNode } from 'react'

import { Bullet, Inline, Spacer, Text } from '@te-digi/styleguide'

type NoteItemProps = {
  children?: ReactNode
  bullet: ReactNode
  title: ReactNode
}

const NoteItem = ({ children, bullet, title }: NoteItemProps) => (
  <Spacer marginBottom="xl">
    <Inline
      alignItems="center"
      gap="md"
    >
      <Bullet
        bulletContent={bullet}
        color="warning"
      />
      <Text fontWeight="semi-bold">{title}</Text>
    </Inline>
    {children && <Spacer marginLeft="xxxl">{children}</Spacer>}
  </Spacer>
)

export { NoteItem }
